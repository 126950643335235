<template lang="pug">
om-modal(
  name="add-campaign-to-experiment"
  :width="800"
  color="light"
  scrollable
  :clickToClose="true"
  @beforeOpen="beforeOpen"
  :headerBorder="true"
)
  template(slot="modal-header")
    .brand-modal-action-icon
      close-icon.cursor-pointer(
        @click.native="$modal.hide('add-campaign-to-experiment')"
        :width="14"
        :height="14"
        color="#C2C2C2"
      )
    om-body-text(bt700md) {{ $t('experiments.selectCampaigns', { domain }) }}
  template(slot="modal-body")
    .add-campaign-container.justify-content-center.col-12.mx-0.px-0.d-flex
      .d-flex.mb-4
        om-input#searchCampaignsForExperiment.search-input.mr-4(
          v-model="searchText"
          :placeholder="$t('experiments.searchCampaigns')"
        )
        .active-input.d-flex.align-items-center
          input#activeCampaignOnly.form-checkbox-input(
            v-model="isActiveOnly"
            type="checkbox"
            autocomplete="off"
            :label="$t('experiments.activeOnly')"
          )
          label.mb-0.ml-2(for="activeCampaignOnly") {{ $t('experiments.activeOnly') }}
      .campaign-list(v-if="!loadingCampaigns")
        template(v-for="campaign in filteredList")
          .campaign-row.d-flex.mb-1.mt-1.align-items-center(
            :class="{ 'in-other-experiment': isInOtherExperiment(campaign) }"
            @click="addCampaignToGroup(campaign)"
          )
            .d-flex.align-items-center
              span.experiment-campaign-name {{ campaign.name }}
              om-chip.other-experiment-name.ml-2.mr-0.my-0(color="secondary") {{ campaign.currentExperimentName }}
            span.created-date {{ formatDate(campaign.createdAt) }}
      .loading-campaigns(v-else)
        img(:src="require('@/assets/editor/svg/loader.svg')")
  template(slot="modal-footer")
</template>
<script>
  import CAMPAIGN_LIST from '@/graphql/CampaignList.gql';
  import dateFormat from '@/mixins/dateFormat';
  import moment from 'moment';
  import { track } from '@/services/xray';

  export default {
    mixins: [dateFormat],
    data() {
      return {
        domain: null,
        domainId: null,
        isActiveOnly: false,
        loadingCampaigns: true,
        campaignList: [],
        searchText: '',
        groupId: null,
        experimentId: null,
        campaignsAlreadyInGroup: [],
      };
    },

    computed: {
      filteredList() {
        let list = this.campaignList.filter((campaign) =>
          campaign.name.toLowerCase().includes(this.searchText.toLowerCase()),
        );
        list = list.filter((campaign) => campaign.status !== 'archived');
        if (this.isActiveOnly) {
          list = list.filter((campaign) => campaign.status === 'active');
        }
        return list;
      },
    },

    methods: {
      async beforeOpen(event) {
        const { domain, domainId, groupId, experimentId, campaignsAlreadyInGroup } = event.params;
        this.domain = domain;
        this.domainId = domainId;
        this.groupId = groupId;
        this.experimentId = experimentId;
        this.campaignsAlreadyInGroup = campaignsAlreadyInGroup;

        await this.fetchCampaignList();
      },

      async fetchCampaignList() {
        this.loadingCampaigns = true;
        const { data } = await this.$apollo.query({
          query: CAMPAIGN_LIST,
          variables: {
            experimentDetails: true,
            filter: {
              domainIds: [this.domainId],
            },
          },
        });

        if (data?.campaignList) {
          this.campaignList = data.campaignList.filter(
            (campaign) => !this.campaignsAlreadyInGroup.includes(campaign._id),
          );
        }

        setTimeout(() => {
          this.loadingCampaigns = false;
        }, 300);
      },
      async addCampaignToGroup(campaign) {
        if (!campaign.currentExperimentId || campaign.currentExperimentId === this.experimentId) {
          this.$modal.hide('add-campaign-to-experiment');
          const isScheduled = campaign.schedule && campaign.schedule.from !== null;
          if (campaign.status === 'active' || isScheduled) {
            this.$modal.show('add-campaign-notification', {
              groupId: this.groupId,
              campaign: { name: campaign.name, id: campaign.id },
              isScheduled,
            });
          } else {
            this.$bus.$emit('experiments:campaignAdded', {
              campaignId: campaign.id,
              groupId: this.groupId,
            });
          }
        }
        track('add-campaign-to-experiment', {
          experimentId: this.experimentId,
          groupId: this.groupId,
          campaign: campaign.id,
        });
      },
      formatDate(date) {
        return moment(date).format(this.shortDateFormat);
      },
      isInOtherExperiment(campaign) {
        return campaign.currentExperimentId && campaign.currentExperimentId !== this.experimentId;
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  .campaign-list
    height: 18rem
    overflow-x: hidden
    overflow-y: scroll

  .add-campaign-container
    flex-direction: column

    .campaign-row
      border-radius: 8px
      padding: 0.5rem 0.75rem
      cursor: pointer

      &:hover
        background: $om-gray-100

      justify-content: space-between
      color: $om-gray-700

      .created-date
        font-size: 0.875rem
        color: $om-gray-600

      .other-experiment-name
        display: none

      &.in-other-experiment
        padding: 0.25rem 0.75rem
        cursor: default
        &:hover
          background: #fff

        .experiment-campaign-name
          color: $om-gray-500

        .other-experiment-name
          display: block

    .loading-campaigns
      height: 132px
      position: relative
      display: flex
      align-items: center
      justify-content: center
      z-index: 10
      font: normal normal normal 0.875rem/1 FontAwesome
      -animation: spin .7s infinite linear
      -moz-animation: spin .7s infinite linear
      -ms-animation: spin .7s infinite linear
      -webkit-animation: spin2 .7s infinite linear

    .search-input
      width: 100%

    .active-input
      label
        font-size: 0.75rem
        white-space: nowrap
        color: $om-gray-700
</style>
