<template lang="pug">
om-modal(
  name="add-campaign-notification"
  @beforeOpen="beforeOpen"
  headerBorder
  :width="425"
  color="light"
)
  template.border-bottom(slot="modal-header")
    .row
      .col
        .font-weight-bold.font-size-1--25 {{ $t('experiments.modal.addCampaign.title') }}
    .brand-modal-action-icon.cursor-pointer(@click="$modal.hide('add-campaign-notification')")
      close-icon(:width="12" :height="12" color="#AAB1C1")
  template(slot="modal-body")
    om-body-text(bt400md v-if="campaign")
      span(
        v-html="$t('experiments.modal.addCampaign.scheduledDescription', { campaignName: campaign.name })"
        v-if="isScheduled"
      )
      span(
        v-html="$t('experiments.modal.addCampaign.activeDescription', { campaignName: campaign.name })"
        v-else
      )
  template(slot="modal-footer")
    .d-flex.align-items-center.justify-content-end
      om-button#add-campaign-cancel.mr-3(ghost @click="skip") {{ $t('experiments.modal.addCampaign.cancel') }}
      om-button#add-campaign-ok(primary @click="done") {{ $t('experiments.modal.addCampaign.ok') }}
</template>
<script>
  export default {
    data() {
      return {
        campaign: null,
        isScheduled: false,
        groupId: null,
      };
    },
    methods: {
      done() {
        this.$modal.hide('add-campaign-notification');
        this.$bus.$emit('experiments:campaignAdded', {
          campaignId: this.campaign.id,
          groupId: this.groupId,
        });
      },
      skip() {
        this.$modal.hide('add-campaign-notification');
      },
      beforeOpen(event) {
        const { campaign, isScheduled, groupId } = event.params;
        this.campaign = campaign;
        this.isScheduled = isScheduled;
        this.groupId = groupId;
      },
    },
  };
</script>
