<template lang="pug">
om-modal.traffic-share-modal(
  name="experiment-traffic-share"
  @beforeOpen="beforeOpen"
  @closed="afterClose"
  headerBorder
  :width="420"
  color="light"
)
  template.border-bottom(slot="modal-header")
    .row
      .col
        .font-weight-bold.font-size-1--25 {{ $t('experiments.modal.trafficShare.title') }}
    .brand-modal-action-icon.cursor-pointer(@click="$modal.hide('experiment-traffic-share')")
      close-icon(:width="12" :height="12" color="#AAB1C1")
  template(slot="modal-body" v-if="experiment")
    om-checkbox.mb-2(
      :id="'evenly-split'"
      data-track="traffic share evenly split"
      v-model="evenlySplit"
      @input="evenlySplitGroups($event)"
      :label="$t('experiments.modal.trafficShare.evenlySplit')"
    )
    small.form-text.text-muted {{ $t('experiments.modal.trafficShare.evenlySplitDescription') }}
    .traffic-share-table.row.mt-5.mb-3
      .col-6.traffic-share-table-title
        om-body-text(bt700xs) {{ $t('experiments.modal.trafficShare.group') }}
      .col-6.traffic-share-table-title.text-right
        om-body-text(bt700xs) {{ $t('experiments.modal.trafficShare.weight') }}
    template(v-for="(group, index) in trafficGroups")
      .row.traffic-share-table-row.mb-3
        .col-6.traffic-share-table-cell-left
          om-heading(h5) {{ group.name }}
        .col-6.traffic-share-table-cell-right
          om-input.traffic-share-input(
            :id="`traffic-group-${index}`"
            type="number"
            v-model="trafficGroups[index].trafficShareInPercentage"
            :error="$v.trafficGroups.$each[index].trafficShareInPercentage.$error"
            :min="0"
            :max="100"
            @blur="onBlur()"
            @input="setNewTrafficPercentages($event, index)"
            suffix="%"
          )
    om-body-text.pt-3.traffic-share-sum(bt400sm :class="{ 'text-danger': sumError }") {{ $t('experiments.modal.trafficShare.sum') }}: {{ trafficSum }}%
  template(slot="modal-footer")
    .d-flex.align-items-center.justify-content-end
      om-button#change-traffic-share-cancel.mr-3(ghost @click="cancel") {{ $t('experiments.modal.trafficShare.cancel') }}
      om-button#change-traffic-share-ok(primary @click="done" :disabled="sumError || fieldError") {{ $t('experiments.modal.trafficShare.done') }}
</template>
<script>
  import UPDATE_TRAFFIC_SHARE from '@/graphql/UpdateTrafficShare.gql';
  import { required, minValue, maxValue } from 'vuelidate/lib/validators';
  import experimentTrafficShare from '@/mixins/experimentTrafficShare';

  export default {
    mixins: [experimentTrafficShare],
    data() {
      return {
        experiment: null,
        evenlySplit: false,
        trafficGroups: [],
        sumError: false,
        trafficSum: 100,
        fieldError: false,
      };
    },
    computed: {
      trafficPercentages() {
        return this.trafficGroups.map((group) => group.trafficShareInPercentage);
      },
    },
    watch: {
      $v: {
        handler() {
          this.fieldError = this.$v?.trafficGroups?.$anyError;
        },
        deep: true,
      },
      trafficPercentages: {
        handler() {
          this.calculateTrafficSum();
        },
        deep: true,
      },
      trafficSum: {
        handler(sum) {
          this.sumError = false;
          if (
            sum !== 100 &&
            !(this.evenlySplit && this.trafficGroups.every((group) => group.trafficShare === 1))
          ) {
            this.sumError = true;
          }
          return sum;
        },
      },
    },

    validations: {
      trafficGroups: {
        $each: {
          trafficShareInPercentage: {
            required,
            minValue: minValue(0),
            maxValue: maxValue(100),
            isCool(value) {
              return value.toString().length <= 4;
            },
          },
        },
      },
    },
    methods: {
      calculateTrafficSum() {
        const sumConvertedToInt = this.trafficGroups.reduce((sum, element) => {
          const trafficShareConvertedToInt = this.convertDecimalsUp({
            number: element.trafficShareInPercentage || 0,
            decimalPlaces: 2,
          });
          sum += trafficShareConvertedToInt;
          return sum;
        }, 0);
        this.trafficSum = this.reduceToNthDecimal({
          number: sumConvertedToInt,
          decimalPlaces: 1,
          reduceDecimalCount: 2,
        });
        if (this.evenlySplit && this.trafficGroups.every((group) => group.trafficShare === 1)) {
          this.trafficSum = 100;
        }
      },
      setNewTrafficPercentages(newTrafficShareInPercentage, inputIndex) {
        this.$nextTick(() => {
          this.calculateNewWeights(newTrafficShareInPercentage, inputIndex);
          this.checkIfEvenlySplit();
        });
      },
      getTrafficShareWeight(newTrafficShare) {
        const groupCount = this.experiment.groups.length;
        const newWeight = this.reduceToNthDecimal({
          number:
            this.convertDecimalsUp({ number: newTrafficShare, decimalPlaces: 1 }) * groupCount,
          decimalPlaces: 4,
          reduceDecimalCount: 3,
        });

        return newWeight;
      },
      completoTrafficto100Percent(newTrafficShareInPercentage, inputIndex) {
        const unchangedIndex = inputIndex === 0 ? 1 : 0;

        this.trafficGroups.splice(unchangedIndex, 1, {
          ...this.trafficGroups[unchangedIndex],
          trafficShare: 2 - this.getTrafficShareWeight(parseFloat(newTrafficShareInPercentage)),
          trafficShareInPercentage: this.reduceToNthDecimal({
            number:
              1000 -
              this.convertDecimalsUp({ number: newTrafficShareInPercentage, decimalPlaces: 1 }),
            decimalPlaces: 1,
            reduceDecimalCount: 1,
          }),
        });

        this.trafficGroups.splice(inputIndex, 1, {
          ...this.trafficGroups[inputIndex],
          trafficShare: this.getTrafficShareWeight(parseFloat(newTrafficShareInPercentage)),
          trafficShareInPercentage: parseFloat(newTrafficShareInPercentage),
        });
      },
      calculateNewWeights(newTrafficShareInPercentage, inputIndex) {
        if (this.trafficGroups.length === 2 && this.trafficSum !== 100) {
          this.completoTrafficto100Percent(newTrafficShareInPercentage, inputIndex);
        } else {
          this.trafficGroups.splice(inputIndex, 1, {
            ...this.trafficGroups[inputIndex],
            trafficShare: this.getTrafficShareWeight(parseFloat(newTrafficShareInPercentage)),
          });
        }
      },
      checkIfEvenlySplit() {
        if (
          this.trafficGroups.every(
            (trafficGroup) =>
              parseFloat(trafficGroup.trafficShareInPercentage) ===
              parseFloat(this.trafficGroups[0].trafficShareInPercentage),
          )
        ) {
          this.evenlySplitGroups();
          this.evenlySplit = true;
        } else {
          this.evenlySplit = false;
        }
      },
      onBlur() {
        this.$v.$touch();
      },
      async beforeOpen(event) {
        const { experiment } = event.params;
        this.experiment = experiment;
        this.trafficGroups = JSON.parse(
          JSON.stringify(
            experiment.groups.map((group) => {
              return {
                ...group,
                trafficShareInPercentage: this.getTrafficShareInPercentage(group.trafficShare),
              };
            }),
          ),
        );
        this.checkIfEvenlySplit();
      },
      async afterClose() {
        this.$v.$reset();
      },
      evenlySplitGroups() {
        this.calculateTrafficSum();
        this.trafficGroups.forEach((element) => {
          element.trafficShare = 1;
          element.trafficShareInPercentage = this.getTrafficShareInPercentage(1);
        });
      },
      cancel() {
        this.$modal.hide('experiment-traffic-share');
      },
      showUpdateNotification(isSuccess) {
        const type = isSuccess ? 'Success' : 'Error';
        this.$notify({
          type: type.toLowerCase(),
          text: this.$t(`notifications.save${type}`),
        });
      },
      async done() {
        if (!this.$v) {
          return;
        }
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const newGroups = this.trafficGroups.map((group) => {
            const { _id, trafficShare } = group;
            return {
              _id,
              trafficShare,
            };
          });
          const { data } = await this.$apollo.mutate({
            mutation: UPDATE_TRAFFIC_SHARE,
            variables: {
              experimentId: this.experiment._id,
              groups: newGroups,
            },
          });
          const isSuccess = data?.updateTrafficShare?.success;
          if (isSuccess) {
            this.$emit('experiments:trafficShareUpdate', { newGroups });
          }
          this.showUpdateNotification(isSuccess);
          this.$modal.hide('experiment-traffic-share');
        }
      },
    },
  };
</script>
<style lang="sass" scoped>
  .traffic-share-table
    display: flex
    align-items: center
    justify-content: center
  .traffic-share-input
    width: 70px
    text-align: right
  .traffic-share-table-cell-left,
  .traffic-share-table-cell-right
    display: flex
    align-items: center
  .traffic-share-table-cell-right
    justify-content: flex-end
  .traffic-share-sum
    text-align: right
</style>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  .traffic-share-modal .brand-modal
    .brand-modal-header
      padding: 1.05rem 2.5rem
    .brand-modal-body,
    .brand-modal-footer
      padding: 1.25rem 2.5rem
</style>
