export default {
  methods: {
    roundToNDecimals({ number, decimalPlaces }) {
      return Math.round(number * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
    },
    convertDecimalsUp({ number, decimalPlaces }) {
      return number * Math.pow(10, decimalPlaces);
    },
    reduceToNthDecimal({ number, decimalPlaces, reduceDecimalCount }) {
      return this.roundToNDecimals({
        number: number / Math.pow(10, reduceDecimalCount),
        decimalPlaces,
      });
    },
    getTrafficShareInPercentage(trafficShare) {
      const groupCount = this.experiment.groups.length;
      return this.roundToNDecimals({
        number: this.convertDecimalsUp({ number: trafficShare, decimalPlaces: 2 }) / groupCount,
        decimalPlaces: 1,
      });
    },
  },
};
