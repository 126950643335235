<template lang="pug">
om-modal(name="end-experiment" headerBorder :width="425" color="light")
  template.border-bottom(slot="modal-header")
    .row
      .col
        .font-weight-bold.font-size-1--25 {{ $t('experiments.modal.end.title') }}
    .brand-modal-action-icon.cursor-pointer(@click="$modal.hide('end-experiment')")
      close-icon(:width="12" :height="12" color="#AAB1C1")
  template(slot="modal-body")
    om-body-text(bt400md)
      span(v-html="$t('experiments.modal.end.description')")
  template(slot="modal-footer")
    .d-flex.align-items-center.justify-content-end
      om-button#end-experiment-cancel.mr-3(ghost @click="skip") {{ $t('experiments.modal.end.cancelBtn') }}
      om-button#end-experiment-ok(primary @click="done") {{ $t('experiments.modal.end.yesBtn') }}
</template>
<script>
  export default {
    methods: {
      done() {
        this.$bus.$emit('experiments:experimentEnded');
        this.$modal.hide('end-experiment');
      },
      skip() {
        this.$modal.hide('end-experiment');
      },
    },
  };
</script>
