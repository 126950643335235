<template lang="pug">
om-modal(name="code-editor" @beforeOpen="beforeOpen" headerBorder :width="600" color="light")
  template.border-bottom(slot="modal-header")
    .row
      .col
        .font-weight-bold.font-size-1--25 {{ $t('experiments.modal.addJsSnippet') }}
    .brand-modal-action-icon.cursor-pointer(@click="close")
      close-icon(:width="12" :height="12" color="#AAB1C1")
  template(slot="modal-body")
    .row.code-row
      om-code-input(v-model="code" :required="true")
  template(slot="modal-footer")
    .d-flex.align-items-center.justify-content-end
      om-button.mr-3(ghost @click="close") {{ $t('cancel') }}
      om-button(primary @click="save" :loading="savingInProgress") {{ $t('save') }}
</template>
<script>
  import UPDATE_JS_SNIPPET_IN_GROUP from '@/graphql/UpdateJsSnippetInGroup.gql';

  export default {
    data() {
      return {
        group: null,
        code: '',
        savingInProgress: false,
      };
    },
    methods: {
      close() {
        this.$modal.hide('code-editor');
      },
      beforeOpen(event) {
        this.group = event?.params?.group;
        this.code = this.group?.jsSnippet || '';
      },
      async save() {
        this.savingInProgress = true;
        const updateObject = {
          groupId: this.group._id,
          jsSnippet: this.code,
        };

        await this.$apollo.mutate({
          mutation: UPDATE_JS_SNIPPET_IN_GROUP,
          variables: updateObject,
        });

        this.savingInProgress = false;
        this.$emit('updateJsSnippetInGroup', updateObject);
        this.close();
      },
    },
  };
</script>
<style lang="sass" scoped>
  .code-row
    height: 45vmin
</style>
